ngb-datepicker{
    position: absolute;
    z-index: 100000;
}

.ngb-dp-months{
    background: $white;
}





