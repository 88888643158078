.countdown-timer{
	font-family: inherit;
	font-size: 80%;
	font-weight: $font-weight-bold;
	text-transform: uppercase;
	text-align: left;
	display: inline-block;
	ul{
		padding-left: 0;
    	list-style: none;
		margin: 0;
		li{
			&:not(:last-child){
				margin-right: .5rem;
			}
			display: inline-block;
			font-size: $h2-font-size;
			text-align: center;
			vertical-align: top;
			font-weight: $font-weight-bold;
			small{
				display: block;
				text-transform: uppercase;
				font-weight: $font-weight-base;
				font-size: 50%;
			}
		}
	}
}