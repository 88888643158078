.bids-table-info{
	@extend ._spacing-m;
	margin-bottom: 0!important;
}
.bids-table{
	@extend ._spacing-m;
	margin-top: 0!important;
	.btn{
		@extend .mt-3;
		@include media-breakpoint-down(sm) {
			float:none!important;
		}
	}
	h4{
		text-transform: none;
	}
	.float-right{
		p{
			display: inline-block;
			@extend .m-3;
			@extend .ml-0;
			@extend .pt-3;
			vertical-align: middle;
		}
		@include media-breakpoint-down(sm) {
			float:none!important;
		}
	}
}