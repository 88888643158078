@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
//
// Bootstrap variables
//

//grid

$grid-breakpoints: (
  xxs:0,
  xs: 480px,
  sm: 620px,
  md: 768px,
  lg: 992px,
  xl: 1260px
);

$container-max-widths: (
  sm: 600px,
  md: 720px,
  lg: 960px,
  xl: 1200px
);

//colours

$body-color:#333333;
$primary:#a01d5b;
$secondary:#1aafc6;
$dark: #000000;
$success: #dff0d8;
$error: #f2dede;
$warning: #fcf8e3;

$theme-colors: (
  primary: $primary,
  secondary: $secondary,
  success: $green,
  info: $cyan,
  warning: $yellow,
  danger: $red,
  light: $white,
  dark: $dark
);

//type

$font-size-base: 1.0625rem;
$paragraph-margin-bottom:1.5rem;

$font-weight-bold:700;
$font-weight-med:600;
$font-weight-base:400;
$line-height-base:27/17;

$h1-font-size: floor(($font-size-base * 2));
$h2-font-size: floor(($font-size-base * 1.76));
$h3-font-size: floor(($font-size-base * 1.2));
$h4-font-size: floor(($font-size-base * 1.05));
$h5-font-size: $font-size-base;
$h6-font-size: floor(($font-size-base * 0.9));

//$lead-font-size: floor(($font-size-base * 1.4));

$headings-font-weight: 700;
$headings-line-height: 60/50;
$headings-margin-bottom: $paragraph-margin-bottom; 
$headings-color:$body-color;

$font-family-sans-serif:'Proxima Nova W01', Helvetica, Arial, sans-serif;
$font-family-base:  $font-family-sans-serif;

//links

$link-color:                theme-color("primary");
$link-hover-color:          darken($link-color, 15%);

//border radius

$border-radius: 0;
$border-radius-lg:0;
$border-radius-sm:0;

$btn-border-radius:           $border-radius ;
$btn-border-radius-lg:        $border-radius-lg ;
$btn-border-radius-sm:        $border-radius-sm ;
$input-border-radius:                   $border-radius ;
$input-border-radius-lg:                $border-radius-lg ;
$input-border-radius-sm:                $border-radius-sm ;
$custom-checkbox-indicator-border-radius:       $border-radius ;
$custom-select-border-radius:       $border-radius ;
$custom-file-border-radius:         $input-border-radius ;
$dropdown-border-radius:            $border-radius ;
$nav-tabs-border-radius:            $border-radius ;
$nav-pills-border-radius:           $border-radius ;
$navbar-toggler-border-radius:      $btn-border-radius ;
$card-border-radius:                $border-radius ;
$card-inner-border-radius:          calc(#{$card-border-radius} - #{$card-border-width}) ;
$tooltip-border-radius:             $border-radius ;
$popover-border-radius:             $border-radius-lg ;
//$badge-border-radius:               $border-radius ;
$modal-content-border-radius:       $border-radius-lg ;
$alert-border-radius:               $border-radius ;
$progress-border-radius:            $border-radius ;
$list-group-border-radius:          $border-radius ;
$thumbnail-border-radius:           $border-radius ;
$breadcrumb-border-radius:          $border-radius ;


// navbar

$navbar-light-color:                 $white;
$navbar-light-hover-color:           $white;
$navbar-light-active-color:          $white;
$navbar-light-disabled-color:        $white;
$navbar-light-toggler-icon-bg:       str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23");
$navbar-light-toggler-border-color:  $white;

// tables

$table-dark-bg:               $secondary;
$table-dark-border-color:     $white;
$table-dark-color:            $white;
$table-border-color:          $white;
$table-border-width:          3px;
$table-cell-padding:          $spacer*1.5;
$table-bg:                    $gray-100;
$table-accent-bg:             $gray-200;
$table-hover-bg:              $gray-300;

//popovers 

$popover-font-size:                   $font-size-sm;
$popover-border-radius:               $border-radius-lg;
$popover-header-bg:                   $primary;
$popover-header-color:                $white;
$popover-arrow-width:               $font-size-base;
$popover-arrow-height:            $font-size-base*0.5;

//form variables

$form-group-margin-bottom: $spacer * 3;
$input-bg:                              $gray-200;
$input-disabled-bg:                     $gray-100;
$input-color:                           $body-color;
$input-border-color:                    $input-bg;
$input-focus-bg:                        $input-bg;
$input-box-shadow:                      none;
$input-placeholder-color:               $gray-500;

//card variables

$card-cap-bg: $card-bg;

@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/type";


//
// @extend styles
//

._bg-image{
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

._spacing-m{
  @extend .my-5;
}

._spacing-mt{
  @extend .mt-5;
}
._spacing-mb{
  @extend .mb-5;
}

._spacing-p{
  @extend .py-5;
}

//
// Layout
//

*:focus {
  outline: 0!important;
  outline: none!important;
}

a{
    @include transition($transition-base);
}
