.popover-header{
	font-size: inherit;
}

.popover-item{
	display: block;
	&:hover{
		cursor: pointer;
	}
}







