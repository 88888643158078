.dashboard-credits{
  @extend ._spacing-m;
  span{
      @extend .h3;
    @extend .text-success;
  }
}

.dashboard-audit{
   @extend ._spacing-m;
}


.dashboard-profile{
   @extend ._spacing-m;
}

.dashboard-plan{
   @extend ._spacing-m;
}

.dashboard-reports{
  @extend ._spacing-m;
  label{
    @extend .h3;
  }
  datepicker label{
    font-size: $font-size-base;
  }
}