.dark{
	background: $table-accent-bg;
	@include media-breakpoint-down(sm) {
		border: 0!important;
	}
	+ .dark{
		border-top: 1px solid $table-border-color;
	}
}

.light{
	background: $table-bg;
	@include media-breakpoint-down(sm) {
		border: 0!important;
	}
	+ .light{
		border-top: 1px solid $table-border-color;
	}
}

.no-bid{
	@extend .text-danger;
	font-size: $h1-font-size*1.75;
	font-weight: $font-weight-bold;
	font-family: serif;
}

.bid-pos{
	@extend .text-white;
	@extend .bg-success;
	@extend .p-1;
	width:40px;
	height: 40px;
	border-radius: 100%;
	text-align: center;
	font-size: $h3-font-size;
	line-height: $h3-font-size*1.5;
	font-weight: $font-weight-bold;
	display: inline-block;
}

.bid-width{
	@include media-breakpoint-up(sm) {
		padding:0.35rem !important;
	}

	.input-group-text {
		padding-left:0.2rem !important;
		padding-right:0.2rem !important;
	}

	.reserve-info {
		padding-top:0.5rem;
	}
}