.user-message{
	@extend ._spacing-m;
	p{
		@extend .p-3;
		@extend .mb-0;
	}
	&.user-message-success{
		background: $success;
		color:darken($success,50%);
		border: 1px solid darken($success,50%);
	}
	&.user-message-error{
		background: $error;
		color:darken($error,50%);
		border: 1px solid darken($error,50%);
	}
	&.user-message-warning{
		background: $warning;
		color:darken($warning,50%);
		border: 1px solid darken($warning,50%);
	}
}