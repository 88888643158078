.sidebar {
    max-width: 300px;
    flex: 0 0 300px;
    background: $body-color;
    @include media-breakpoint-down(sm) {
        flex: 0 0 100%;
        max-width: 100%;
        padding-right:0;
    }
}
.sidebar-body {
    background: $body-color;
    color: white;
    @extend .px-4;
    @extend ._spacing-p;
    height: 100%;
    position: fixed;
    overflow: auto;
    z-index:100;
    width: 300px;
    @extend .text-white;
    // @include media-breakpoint-down(lg) {
    // 	width: 25%;
    // }
    // @include media-breakpoint-down(md) {
    // 	width: 30%;
    // }
    @include media-breakpoint-down(sm) {
        width: 100%;
    	position: relative;
    	height:auto;
    	padding:$grid-gutter-width/2 $grid-gutter-width!important;
    }
     a{
        color:white;
        text-decoration: none;
        &.active,&:hover,&:active,&:focus{
            color:theme-color('secondary');
        }
        &:hover{
            cursor: pointer;
        }
    }
    ul{
        @extend .nav;
        @extend .flex-column;
        li{
            @extend .nav-item;
            text-transform: uppercase;
            font-weight: $font-weight-bold;
            a{
                @extend .nav-link;
                position:relative;
                padding-left:35px;
            }
        }
        span{
            position: absolute;
            top:0;
            left:0;
            font-size: 25px;
            width:25px;
            text-align: center;
        }
    }
    .media{
        @extend ._spacing-m;
    }
    .media-left{
        @extend .mr-3;
    }
    .media-body{
        line-height: 1.2;
        a{
            position:relative;
        }
        span{
           font-size:80%;
           position:absolute;
           right:0;
           top:5px;
           display:block;
        }
        small{
            padding-right:20px;
        }
    }

    
}

.profile-img {
    border-radius:50%;
}

.sidebar-content{
    @include media-breakpoint-down(sm) {
        display: none;
        &.sidebar-content-open{
            display: block;
        }
    }
}