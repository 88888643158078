.audit-table{
	@extend ._spacing-m;
	table > tbody > tr > td {
		text-align: left;
		vertical-align: top;
		@include media-breakpoint-down(sm) {
			text-align: center;
		}
		+ td{
			@include media-breakpoint-down(sm) {
				padding-top: 0;
			}
		}
		&.audit-details{
			width: 30%;
			@include media-breakpoint-down(sm) {
				padding-bottom: 0;
				width: auto;
			}
		}
	}
	h4{
		@extend .mb-2;
	}
}

