@import url("//fast.fonts.net/cssapi/ccf73b16-331d-4a70-b981-87d5d66371f0.css");


@import "variables";



/////////////////////////////////////////////////

@import "../node_modules/bootstrap/scss/print";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/code";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/tables";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/custom-forms";
@import "../node_modules/bootstrap/scss/input-group";
//@import "../node_modules/bootstrap/scss/dropdown";
//@import "../node_modules/bootstrap/scss/button-group";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/navbar";
//@import "../node_modules/bootstrap/scss/breadcrumb";
//@import "../node_modules/bootstrap/scss/pagination";
@import "../node_modules/bootstrap/scss/badge";
//@import "../node_modules/bootstrap/scss/jumbotron";
@import "../node_modules/bootstrap/scss/alert";
//@import "../node_modules/bootstrap/scss/progress";
@import "../node_modules/bootstrap/scss/media";
//@import "../node_modules/bootstrap/scss/list-group";
@import "../node_modules/bootstrap/scss/close";
//@import "../node_modules/bootstrap/scss/modal";
//@import "../node_modules/bootstrap/scss/tooltip";
@import "../node_modules/bootstrap/scss/popover";
//@import "../node_modules/bootstrap/scss/carousel";
@import "../node_modules/bootstrap/scss/card";


//
// Bootstrap overrides
//

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
   text-transform: uppercase;
    a,a:hover,a:focus,a:active{
      color:inherit;
    }
}

.text-white{
	h1, h2, h3, h4, h5, h6,
	.h1, .h2, .h3, .h4, .h5, .h6 {
	    color:inherit;
	    a,a:hover,a:focus,a:active{
	      color:inherit;
	    }
	}
	a,a:hover,a:focus,a:active{
      color:inherit;
    }
}

.table{
	table-layout: fixed;
	@include media-breakpoint-down(sm) {
		display: block;
	}
	> tbody {
		@include media-breakpoint-down(sm) {
			display: block;
		}
		> tr , > tbody > tr {
			@include media-breakpoint-down(sm) {
				display: block;
			}
			> td, > th{
				vertical-align: middle;
				@include media-breakpoint-down(sm) {
					display: block;
					text-align: center;
					width: auto;
					border: none;
				}
			}
		}
	}
	> tbody > tr > td {
		text-align: center;
	}
	> thead{
		@include media-breakpoint-down(sm) {
			display: none;
		}
		> tr > th {
			text-transform: uppercase;
			border-bottom: 0;
			text-align: center;
			vertical-align: middle;
			font-size: 85%;
		}
	}
	&.table-hover{
		> tbody > tr:hover{
			cursor: pointer;
		}
	}
	.form-control{
    	background: $white;
    }
}

.btn{
	text-transform: uppercase;
	font-weight: $font-weight-bold;
	&:hover{
		cursor: pointer;
	}
}

.card-deck{
	margin-bottom: $grid-gutter-width;

	.card{
		margin-bottom: $grid-gutter-width;
		@include media-breakpoint-down(md) {
			display: block;
			flex:0 0 100%;

		}
		@include media-breakpoint-down(sm) {
			//margin-left: 0;
			//margin-right: 0;
		}
		h4 + p{
			font-weight: $font-weight-bold;
			text-transform: uppercase;
			.h1{
				margin-bottom: 0;
			}
			@include media-breakpoint-only(lg) {
				span{
					display: block;
				}
			}
		}
		h4{
			margin-bottom: 0;
			@extend .text-primary;
		}
		ul{
			@extend .list-unstyled;
			li{
				@extend .mb-3;
				@extend .pl-4;
				position: relative;
				&:before{
					content: '@';
					position: absolute;
					left: 0;
					top:0;
					@extend .text-primary;
				}
				&.muted{
					opacity: 0.1;
				}
			}
		}
		&.active {
			border: 2px $primary solid;
		}
	}

}

.card-footer {
	border-top:none;
}

label{
	text-transform: uppercase;
	font-weight: $font-weight-bold;
}

.form{
	.col.small{
		line-height: $headings-line-height;
		@include media-breakpoint-down(xs) {
			margin-top: $spacer*0.5;
		}
	}
}

.form-check label{
	text-transform: none;
	font-weight: $font-weight-base;
}

.badge-pill{
	@extend .py-2;
	@extend .px-3;
	@extend .m-1;
	white-space: normal;
}

.navbar-toggler {
    color: $navbar-light-color;
    border-color: $navbar-light-toggler-border-color;
    display: none;
    float: right;
    @include media-breakpoint-down(sm) {
    	display: block;
    }
  }

  .navbar-toggler-icon {
    background-image: $navbar-light-toggler-icon-bg;
  }

//////////////////////////////// ICONS //////////////////////////////// 

//Import the fonts
@font-face {
  font-family: 'wpicons';
  src: url('./assets/fonts/wpicons.eot');
  src: url('./assets/fonts/wpicons.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/wpicons.woff') format('woff'),
       url('./assets/fonts/wpicons.woff2') format('woff2'),
       url('./assets/fonts/wpicons.ttf') format('truetype'),
       url('./assets/fonts/wpicons.svg?#wpicons') format('svg');
}

.icon,
[class^="icon-"]:before, [class*=" icon-"]:before {
  position: relative;
  display: inline-block;
  font-family: 'wpicons'!important;
  text-transform: none;
  font-size: $font-size-base*1.25;//21px
  font-style: normal;
  font-weight: normal;
  //line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
  text-decoration: inherit;
}

h2 .icon {
  line-height: $line-height-base;
}

.icon-doc-1:before { content: '\64'; } /* 'd' */
.icon-feedback-4:before { content: '\e800'; } /* '' */
.icon-feedback-2:before { content: '\e801'; } /* '' */
.icon-feedback-3:before { content: '\e802'; } /* '' */
.icon-feedback-5:before { content: '\e803'; } /* '' */
.icon-feedback-1:before { content: '\e804'; } /* '' */
.icon-user-secret:before { content: '\e805'; } /* '' */
.icon-lock-open:before { content: '\e806'; } /* '' */
.icon-pin:before { content: '\e807'; } /* '' */
.icon-tags:before { content: '\e808'; } /* '' */
.icon-bookmark:before { content: '\e809'; } /* '' */
.icon-thumbs-up-1:before { content: '\e80a'; } /* '' */
.icon-thumbs-down-1:before { content: '\e80b'; } /* '' */
.icon-share:before { content: '\e80c'; } /* '' */
.icon-file-code:before { content: '\e80d'; } /* '' */
.icon-folder:before { content: '\e80e'; } /* '' */
.icon-rss:before { content: '\e80f'; } /* '' */
.icon-cog:before { content: '\e810'; } /* '' */
.icon-wrench:before { content: '\e811'; } /* '' */
.icon-basket:before { content: '\e812'; } /* '' */
.icon-calendar:before { content: '\e813'; } /* '' */
.icon-mic:before { content: '\e814'; } /* '' */
.icon-right-hand:before { content: '\e815'; } /* '' */
.icon-fast-fw:before { content: '\e816'; } /* '' */
.icon-award:before { content: '\e817'; } /* '' */
.icon-sun-1:before { content: '\e818'; } /* '' */
.icon-space-shuttle:before { content: '\e819'; } /* '' */
.icon-leaf:before { content: '\e81a'; } /* '' */
.icon-ellipsis:before { content: '\e81b'; } /* '' */
.icon-tint:before { content: '\e81c'; } /* '' */
.icon-toggle-on:before { content: '\e81d'; } /* '' */
.icon-asterisk:before { content: '\e81e'; } /* '' */
.icon-flame:before { content: '\e81f'; } /* '' */
.icon-card:before { content: '\e820'; } /* '' */
.icon-megaphone:before { content: '\e821'; } /* '' */
.icon-rocket:before { content: '\e822'; } /* '' */
.icon-taxi:before { content: '\e823'; } /* '' */
.icon-bicycle:before { content: '\e824'; } /* '' */
.icon-money:before { content: '\e825'; } /* '' */
.icon-hammer:before { content: '\e826'; } /* '' */
.icon-gauge:before { content: '\e827'; } /* '' */
.icon-coffee:before { content: '\e828'; } /* '' */
.icon-food:before { content: '\e829'; } /* '' */
.icon-beer:before { content: '\e82a'; } /* '' */
.icon-medkit:before { content: '\e82b'; } /* '' */
.icon-bed:before { content: '\e82c'; } /* '' */
.icon-shield:before { content: '\e82d'; } /* '' */
.icon-paw:before { content: '\e82e'; } /* '' */
.icon-cubes:before { content: '\e82f'; } /* '' */
.icon-bomb:before { content: '\e830'; } /* '' */
.icon-plug:before { content: '\e831'; } /* '' */
.icon-at:before { content: '\e832'; } /* '' */
.icon-brush:before { content: '\e833'; } /* '' */
.icon-visa:before { content: '\e834'; } /* '' */
.icon-mastercard:before { content: '\e835'; } /* '' */
.icon-amex:before { content: '\e836'; } /* '' */
.icon-html5:before { content: '\e837'; } /* '' */
.icon-play:before { content: '\e838'; } /* '' */
.icon-search:before { content: '\e839'; } /* '' */
.icon-email:before { content: '\e83a'; } /* '' */
.icon-star:before { content: '\e83b'; } /* '' */
.icon-tick:before { content: '\e83c'; } /* '' */
.icon-cross:before { content: '\e83d'; } /* '' */
.icon-plus:before { content: '\e83e'; } /* '' */
.icon-question:before { content: '\e83f'; } /* '' */
.icon-info:before { content: '\e840'; } /* '' */
.icon-thumbs-up:before { content: '\e841'; } /* '' */
.icon-thumbs-down:before { content: '\e842'; } /* '' */
.icon-warning:before { content: '\e843'; } /* '' */
.icon-doc:before { content: '\e844'; } /* '' */
.icon-phone:before { content: '\e845'; } /* '' */
.icon-share-1:before { content: '\e846'; } /* '' */
.icon-clock:before { content: '\e847'; } /* '' */
.icon-down:before { content: '\e848'; } /* '' */
.icon-left:before { content: '\e849'; } /* '' */
.icon-right:before { content: '\e84a'; } /* '' */
.icon-up:before { content: '\e84b'; } /* '' */
.icon-refresh:before { content: '\e84c'; } /* '' */
.icon-wifi:before { content: '\e84d'; } /* '' */
.icon-mobile:before { content: '\e84e'; } /* '' */
.icon-lightning:before { content: '\e84f'; } /* '' */
.icon-ticket:before { content: '\e850'; } /* '' */
.icon-floppy:before { content: '\e851'; } /* '' */
.icon-mail:before { content: '\e852'; } /* '' */
.icon-flag:before { content: '\e853'; } /* '' */
.icon-sun:before { content: '\e854'; } /* '' */
.icon-rain:before { content: '\e855'; } /* '' */
.icon-umbrella:before { content: '\e856'; } /* '' */
.icon-news:before { content: '\e857'; } /* '' */
.icon-twitter:before { content: '\e858'; } /* '' */
.icon-facebook:before { content: '\e859'; } /* '' */
.icon-gift:before { content: '\e85a'; } /* '' */
.icon-fire:before { content: '\e85b'; } /* '' */
.icon-tv:before { content: '\e85c'; } /* '' */
.icon-van:before { content: '\e85d'; } /* '' */
.icon-wallet:before { content: '\e85e'; } /* '' */
.icon-speech:before { content: '\e85f'; } /* '' */
.icon-home:before { content: '\e860'; } /* '' */
.icon-plane:before { content: '\e861'; } /* '' */
.icon-motorcycle:before { content: '\e862'; } /* '' */
.icon-pencil:before { content: '\e863'; } /* '' */
.icon-chart-pie:before { content: '\e864'; } /* '' */
.icon-power:before { content: '\e865'; } /* '' */
.icon-pound:before { content: '\f154'; } /* '' */
.icon-instagram:before { content: '\f16d'; } /* '' */
.icon-cube:before { content: '\f1b2'; } /* '' */
.icon-cog-1:before { content: '\e866'; } /* '' */
.icon-eye:before { content: '\e867'; } /* '' */

//
// shared
//

#content{
	// width: 80%;
 //    //padding-top: 40px;
 //    height:100%;
 //    @extend ._spacing-m;
 //    @include media-breakpoint-down(lg) {
 //        width: 75%;
 //    }
 //    @include media-breakpoint-down(md) {
 //        width: 70%;
 //    }
 //    @include media-breakpoint-down(sm) {
 //        width: 100%;
 //        height:auto;
 //    }
 //    float: right;
	.text-right{
		@include media-breakpoint-down(lg) {
			text-align: left!important;
		}
		.btn{
			@extend .mr-2;
			@extend .mb-2;
		}
	}
	h1{
		margin-bottom: 0;
		@include media-breakpoint-down(md) {
			margin-bottom: $headings-margin-bottom;
		}
	}
}

.mobile-label{
	@extend .text-secondary;
	font-weight: $font-weight-bold;
	text-transform: uppercase;
	display: none;
	@include media-breakpoint-down(sm) {
		display: block;
	}
}

.text-container{
	@extend ._spacing-m;
}

.overlay{
	width:500px;
	background:#f2f2f2;
    position:fixed;
    z-index:1;
    top:0;
    right:0;
    height:100%;
    overflow:hidden;
    overflow-y:auto;
    -webkit-box-shadow: 2px 2px 2px 2px rgba(101, 121, 131, 0.4);
    -moz-box-shadow: 2px 2px 2px 2px rgba(101, 121, 131, 0.4);
    box-shadow: 2px 2px 2px 2px rgba(101, 121, 131, 0.4);
    @extend .p-3;
    @include media-breakpoint-down(sm) {
         position:relative;
         top:auto;
         right:auto;
         height:auto;
         -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        width:100%;
       	margin-top: $headings-margin-bottom;
    }
    .detail {
        min-height:150px;
    }
    .form-control{
    	background: $white;
    }
    .form-group{
    	margin-bottom: $spacer;
    }
    .btn{
    	@extend .mr-1;
    	@extend .mb-1;
    	font-size: 85%;
    }
    pre{
    	@extend .p-2;
    	@extend .border;
    	@extend .mr-1;
    }
}


.required {
    color: crimson;
}

//
// module/component imports 
//
@import "./app/sidebar/sidebar.component.scss";
@import "./app/app.component.scss";

@import "./app/shared/shared.module.scss";
@import "./app/sellers/sellers.module.scss";
@import "./app/rewards/rewards.module.scss";
@import "./app/message/message.module.scss";
@import "./app/bids/bids.module.scss";
@import "./app/dashboard/dashboard.module.scss";
@import "./app/all-products/all-products.module.scss";
@import "./app/audit-trail/audit-trail.module.scss";
//@import "./app/mailbox/mailbox.module.scss";


app-form {
	width: 100%
}




.file-upload{
	width: 100%;
	display: table;
}
	.file-upload-inner{
		@extend .form-control;
		display: table-cell;
		vertical-align: middle;
		text-align: center;
		image-control{
			margin: $input-padding-x 0;
			display: block;
		}
	}


.form-group-wrapper{
	margin-bottom:$form-group-margin-bottom;
	.form-group{
		margin-bottom: $spacer;
	}
}

.form{
	.col.small{
		line-height: $headings-line-height;
		@include media-breakpoint-down(xs) {
			margin-top: $spacer*0.5;
		}
	}
}
.offset-error {
	@extend .offset-xl-4, .offset-md-5, .col, .text-danger, .small, .pt-2;
}


//
// provider fixes
//

.content-form, .plans{
	.container{
		max-width: 100%;
		@include media-breakpoint-down(lg) {
			.row > *[class*="col-"]{
				max-width: 100%;
				flex: 0 0 100%;
			}
		}
		.m-auto{
			margin: initial!important;
		}
	}
}

//
// module/component imports 
//
@import "./app/sidebar/sidebar.component.scss";
@import "./app/app.component.scss";


.file-upload img {
	max-width: 400px;
	max-height: 300px;
	margin:0 auto;
}

/* Stripe elements */
.StripeElement {
	padding:$spacer/2;
	background: $input-bg;
}

.terms-conditions {

	ol { counter-reset: item }
	li { display: block }
	li:before { content: counters(item, ".") " "; counter-increment: item }

	.heading {font-size: 1.2em; font-weight:bold; margin-left:1rem;}

	height:18rem;
	overflow-y:scroll;
	border: 0.01rem gray solid;
	margin-bottom: $grid-gutter-width*2;
	padding: $grid-gutter-width;

}

.plan-confirmation-text {

	font-size:$h3-font-size;
	font-weight:bold;
	margin-bottom: $grid-gutter-width*2;

}

.confirm-accept-terms {

	input {
		margin-left:$grid-gutter-width;
		margin-top:0.3rem;

	}

	label {
		margin-left:0.625rem;
	}
}
app-form, app-sellers {
	width: 100%;
}


.confirm-accept-terms {

	input {
		margin-left:$grid-gutter-width;
		margin-top:0.3rem;

	}

	label {
		margin-left:0.625rem;
	}

}

.benefit-input{
	display:inline !important;
	width:90% !important;
}

.delete-benefit{
	border:1px $primary solid;
	padding:0 5px 0 5px;
	margin-left:10px;
	color:$primary;
	cursor:pointer;
}

.popular-badge {
	
	background-color:$primary;
	color:$light;
	height:100px;
	width:100px;
	padding-top:20px;
	text-align:center;
	float:right;
	font-weight:bold;
	border-radius:50%;
	transform: rotate(20deg);
	-webkit-transform: rotate(20deg);
  
}

.dropdown-item{
	background:theme-color('dark');
	border:none;
	width:100%;
	display:block;
	padding:0;
	margin:0;
	&:hover,&:active,&:focus{
		cursor: pointer;
	}
	&:hover,&:active,&:focus, &.active{
		background:theme-color('dark');
	}
	&.active > *{
		color:$white;
		background:$gray-800;
	}
	> *{
		color:$white;
		padding:$spacer/2;
		display:block;
		@include transition($transition-base);
		&:hover,&:active,&:focus{
			color:$white;
			background:$gray-800;

		}
	}
}

@media (max-width: 1100px){
	.color-picker{
		left:auto!important;
		right:$grid-gutter-width/2!important;
	}
}
